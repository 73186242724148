import ninjaConfig from '../config/ninja';
import regionConfig from '../config/region';
import { Trackers } from '../const';
import { cookieStorage, getCookieExpirationDate, getCookieName } from '../cookies';
import { collectCalls, getDefaultParams as ninjaGetDefaultParams } from '../core';
import { makeMapping, makeObjectMapping } from '../mapping';
import { eucex, getProtocol, loadScript } from '../utils';

let SCRIPT_TAG_ID = 'gtm-script-tag';
let SCRIPT_URL = 'www.googletagmanager.com/gtm.js?id=';

// Link event
let isLinkEvent = false;
let initPushed = false;
// Entry function
export function trackPage(params) {
  init(params, function () {
    let newValues;

    if (params.pageName) {
      newValues = makeMapping(Trackers.GTM, 'trackPage', params.pageName);
      if (newValues.key === false || newValues.value === false) {
        return;
      }

      window.dataLayer.push({
        processed: true,
        event: newValues.value,
      });
    }
  });
}

// Entry function
export function trackEvent(params) {
  init(params, function () {
    let eventData = [];
    let eventName;
    let newValues;

    if (params.eventData.category) {
      eventData.push(params.eventData.category);
    }
    if (params.eventData.action) {
      eventData.push(params.eventData.action);
    }
    if (params.eventData.label) {
      eventData.push(params.eventData.label);
    }
    if (params.eventData.value) {
      eventData.push(params.eventData.value);
    }

    eventName = eucex(convertString(eventData.join('_')));
    newValues = makeMapping(Trackers.GTM, 'trackEvent', eventName);
    if (newValues.key === false || newValues.value === false) {
      onHitSentOk();
      return;
    }

    window.dataLayer.push({
      processed: true,
      event: newValues.value,
    });

    setTimeout(() => onHitSentOk(), 500);
  });
}

// Entry function - Track a link event
export function trackLinkEvent(params) {
  isLinkEvent = true;
  trackEvent(params);
}

function getToken() {
  let code = regionConfig.custom[ninjaConfig.siteUrl].config.G.code;

  if (ninjaConfig.environment !== 'production') {
    // Allow Overwrite only on non-prod
    if (ninjaConfig.custom !== false && undefined !== ninjaConfig.custom.gtm && undefined !== ninjaConfig.custom.gtm.siteCode) {
      code = ninjaConfig.custom.gtm.siteCode;
    }
  }

  return code;
}

// Internal function - Init the tracker
function init(params, callBack) {
  let code = getToken();
  let useCustomDataLayer = true;

  if (!initPushed) {
    for (const data of window.dataLayer) {
      if (undefined !== data.trackPage) {
        useCustomDataLayer = false;
      } else if (undefined !== data.trackEvent) {
        useCustomDataLayer = false;
      }
    }

    getCustomParams(params, useCustomDataLayer);

    window.dataLayer.push({
      processed: true,
      'gtm.start': new Date().getTime(),
      event: 'gtm.js',
    });

    loadScript(getProtocol() + SCRIPT_URL + code, SCRIPT_TAG_ID, function () {
      initPushed = true;

      if (callBack) {
        callBack();
      }
    });
  } else {
    if (callBack) {
      callBack();
    }
  }
}

function getCustomParams(params, useCustomDataLayer) {
  let invite = [];
  let inviteParams;
  let date;
  let value;
  let landingParams;
  let trackParams = getTrackParams(params, useCustomDataLayer);
  let cookieNameIv = getCookieName('invite');
  let cookieNameLd = getCookieName('ldTd');

  // Invite
  if (params.invite) {
    inviteParams = params.invite.match(/^\s*([a-zA-Z0-9\-]{1,64})(?:_([a-zA-Z0-9%\-]{0,128})).*/);
    if (inviteParams !== null && inviteParams.length > 0) {
      invite = inviteParams;
      try {
        if (navigator.cookieEnabled) {
          date = new Date();
          value = '"sr=' + invite[1] + '&cn=' + invite[2] + '&td=' + Math.floor(date.getTime() / 1000) + '"';
          cookieStorage.set(cookieNameIv, value, {
            expires: 30,
            path: '/',
            domain: ninjaConfig.cookieDomain,
          });
        }
      } catch (ignore) {
        //
      }
    }
  } else {
    try {
      if (navigator.cookieEnabled) {
        inviteParams = (cookieStorage.get(cookieNameIv) || '').match(/"sr=([A-Za-z0-9\-]+)&cn=([A-Za-z0-9%\-]+)&td=[0-9]+"/);
        if (inviteParams !== null && inviteParams.length > 0) {
          invite = inviteParams;
        }
      }
    } catch (ignore) {
      //
    }
  }

  if (invite.length > 1) {
    trackParams.invs = invite[1].toLowerCase();
    trackParams.invc = invite[2].toLowerCase();
  }
  trackParams.event = 'INV_AVAILABLE';

  // Landing Page
  try {
    if (navigator.cookieEnabled) {
      landingParams = (cookieStorage.get(cookieNameLd) || '').match(/true/);
      if (landingParams === null) {
        cookieStorage.set(cookieNameLd, 'true', {
          expires: getCookieExpirationDate(1, 'min'),
          path: '/',
          domain: ninjaConfig.cookieDomain,
        });
        trackParams.landing_page = 'true';
      }
    }
  } catch (ignore) {
    //
  }

  window.dataLayer.push(trackParams);
}

/**
 * Get list of default params
 * @returns {Record<string, any>}
 */
function getDefaultParams() {
  return {};
}

/**
 * Get signle object containing custom, ninja default and tracker default params.
 * They are encoded and mapped to the tracker's matrix
 * @param {Record<string, any>} params
 * @returns {Record<string, any>}
 */
function getTrackParams(params, useCustomDataLayer) {
  let trackParams = { processed: true };
  let customParams;
  let ninjaDefaultParams = makeObjectMapping(Trackers.GTM, ninjaGetDefaultParams(params));
  let moduleDefaultParams = makeObjectMapping(Trackers.GTM, getDefaultParams());

  // Copy data from the custom dataLayer
  if (useCustomDataLayer) {
    // apply shared default params
    customParams = Object.assign({}, params.customParams, ninjaDefaultParams);

    // apply tracker's default params
    customParams = Object.assign({}, customParams, moduleDefaultParams);

    Object.keys(customParams).forEach(key => {
      if (typeof customParams[key] !== 'function') {
        trackParams[key] = params.customParams[key];
      }
    });

    if (params.pageName) {
      trackParams.trackPage = params.pageName;
    }
  }

  return trackParams;
}

function convertString(text) {
  if (typeof text === 'string') {
    return text
      .toLowerCase()
      .replace(/[^a-z0-9\/_]/g, '-')
      .replace(/\s{2}/g, ' ')
      .replace(/\s/g, '-')
      .replace(/---/g, '-')
      .replace(/---/g, '-')
      .replace(/--/g, '-');
  }
  return text;
}

// Internal function - Callback after finish one hit
function onHitSentOk() {
  if (isLinkEvent) {
    collectCalls();
    isLinkEvent = false;
  }
}
