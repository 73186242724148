/**
 * This file controls which trackers will be included in the build.
 * Trackers are provided as an env variables
 * include/exlude trackers based on those env variables.
 * Hydra is alwayas included, regardless of provided variable
 */
import * as braze from '../trackers/braze';
import * as clevertap from '../trackers/clevertap';
import * as facebook from '../trackers/facebook';
import * as ga from '../trackers/ga';
import * as gtag from '../trackers/gtag';
import * as gtm from '../trackers/gtm';
import * as hydra from '../trackers/hydra';
import * as laquesis from '../trackers/laquesis';
import * as mixpanel from '../trackers/mixpanel';

let trackers = {
  hydra: hydra,
};

if (process.env.__LAQUESIS__) {
  trackers.laquesis = laquesis;
}

if (process.env.__GA__) {
  trackers.ga = ga;
}

if (process.env.__MIXPANEL__) {
  trackers.mixpanel = mixpanel;
}

if (process.env.__CLEVERTAP__) {
  trackers.clevertap = clevertap;
}

if (process.env.__GTM__) {
  trackers.gtm = gtm;
}

if (process.env.__GTAG__) {
  trackers.gtag = gtag;
}

if (process.env.__FACEBOOK__) {
  trackers.facebook = facebook;
}

if (process.env.__BRAZE__) {
  trackers.braze = braze;
}

export default trackers;
