import ninjaConfig from '../config/ninja';
import regionConfig from '../config/region';
import { Trackers } from '../const';
import { collectCalls, getDefaultParams as ninjaGetDefaultParams } from '../core';
import { makeMapping, makeObjectMapping } from '../mapping';
import { deucex, getProtocol, loadScript } from '../utils';

let SCRIPT_TAG_ID = 'ga-script-tag';
let SCRIPT_URL = 'www.google-analytics.com/analytics.js';

// Link event
let isLinkEvent = false;
let initPushed = false;

// Entry function - Track a page
export function trackPage(params) {
  let oldTag = document.getElementById(SCRIPT_TAG_ID);
  initPushed = false;
  if (oldTag) {
    initPushed = true;
  }
  init(params, function () {
    let trackParams = getTrackParams(params);
    let newValues;
    let pageName;
    let hitContent = {};

    newValues = makeMapping(Trackers.GA, 'trackPage', params.pageName);
    if (newValues.key === false || newValues.value === false) {
      return;
    }
    pageName = convertPageNameString(deucex(newValues.value));

    hitContent.hitType = 'pageview';
    window.ga('clientNinja.set', 'contentGroup1', pageName);

    // if (ninjaConfig.siteUrl === "www.olx.ua") {
    hitContent.page = window.location.pathname;
    // hitContent.location = document.location.protocol + '//' + document.location.hostname + document.location.pathname + document.location.search;
    // } else {
    // hitContent.page = "/" + pageName;
    // hitContent.location = window.location.pathname + window.location.search;
    // }

    // hitContent.title = "test_title";
    Object.keys(trackParams).forEach(key => {
      hitContent[key] = trackParams[key];
    });

    window.ga('clientNinja.send', hitContent);
  });
}

// Entry function - Track an event
export function trackEvent(params) {
  // If first call is an event
  let oldTag = document.getElementById(SCRIPT_TAG_ID);
  initPushed = false;
  if (oldTag) {
    initPushed = true;
  }
  init(params, function () {
    if (params.eventData.category) {
      let trackParams = getTrackParams(params);
      let eventData = [];
      let eventName;
      let newValues;
      let hitContent = {};

      if (params.eventData.category) {
        eventData.push(params.eventData.category);
      }
      if (params.eventData.action) {
        eventData.push(params.eventData.action);
      }
      if (params.eventData.label) {
        eventData.push(params.eventData.label);
      }
      if (params.eventData.value) {
        eventData.push(params.eventData.value);
      }
      eventName = convertString(eventData.join('_'));

      newValues = makeMapping(Trackers.GA, 'trackEvent', eventName);
      if (newValues.key === false || newValues.value === false) {
        onHitSentOk();
        return;
      }
      eventName = newValues.value;

      hitContent.hitType = 'event';
      hitContent.eventCategory = eventName.substr(0, eventName.indexOf('_'));
      if (hitContent.eventCategory === '') {
        hitContent.eventCategory = 'default';
      }
      hitContent.eventLabel = eventName;
      hitContent.eventAction = 'click';

      Object.keys(trackParams).forEach(key => {
        hitContent[key] = trackParams[key];
      });

      hitContent.hitCallback = onHitSentOk;
      window.ga('clientNinja.send', hitContent);
    }
  });
}

// Entry function - Track a link event
export function trackLinkEvent(params) {
  isLinkEvent = true;
  trackEvent(params);
}

function getToken() {
  let token = regionConfig.custom[ninjaConfig.siteUrl].config[Trackers.GA].code;
  if (ninjaConfig.environment !== 'production') {
    // Allow Overwrite only on non-prod
    if (
      ninjaConfig.custom !== false &&
      undefined !== ninjaConfig.custom.ga &&
      undefined !== ninjaConfig.custom.ga.siteCode &&
      ninjaConfig.environment !== 'production'
    ) {
      token = ninjaConfig.custom.ga.siteCode;
    }
  }

  return token;
}

// Internal function - Init the tracker
function init(params, callBack) {
  window['ga-disable-' + getToken()] = false;
  if (initPushed === false) {
    let token = getToken();

    window.GoogleAnalyticsObject = 'ga';
    window.ga =
      window.ga ||
      function () {
        window.ga.q = window.ga.q || [];
        window.ga.q.push(arguments);
      };
    window.ga.l = +new Date();
    initPushed = true;

    window.ga('create', token, 'auto', 'clientNinja');
    window.ga('clientNinja.set', 'anonymizeIp', true);

    loadScript(getProtocol() + SCRIPT_URL, SCRIPT_TAG_ID, callBack);
  } else {
    if (callBack) {
      callBack();
    }
  }
}

// Disable SDK
export function cleanup() {
  window['ga-disable-' + getToken()] = true;
}

function getCustomParams(params) {
  return makeObjectMapping(Trackers.GA, params.customParams);
}

/**
 * Get list of default params
 * @returns {Record<string, any>}
 */
function getDefaultParams() {
  return {};
}

/**
 * Get signle object containing custom, ninja default and tracker default params.
 * They are encoded and mapped to the tracker's matrix
 * @param {Record<string, any>} params
 * @returns {Record<string, any>}
 */
function getTrackParams(params) {
  let trackParams;
  let customParams = getCustomParams(params);
  let ninjaDefaultParams = makeObjectMapping(Trackers.GA, ninjaGetDefaultParams(params));
  let moduleDefaultParams = makeObjectMapping(Trackers.GA, getDefaultParams());

  // apply shared default params
  trackParams = Object.assign({}, customParams, ninjaDefaultParams);

  // apply tracker's default params
  trackParams = Object.assign({}, trackParams, moduleDefaultParams);

  return trackParams;
}

function convertString(text) {
  if (typeof text === 'string') {
    return text.toLowerCase().replace(/\s{2}/g, ' ').replace(/\s/g, '_').replace(/\//g, '_').replace(/-/g, '_');
  }
  return text;
}

function convertPageNameString(text) {
  if (typeof text === 'string') {
    return text.replace(/[^a-zA-Z0-9\/]/g, '_');
  } else {
    return text;
  }
}

// Internal function - Callback after finish one hit
function onHitSentOk() {
  if (isLinkEvent) {
    collectCalls();
    isLinkEvent = false;
  }
}
