export const NINJA_CDN = 'https://ninja.data.olxcdn.com';

export const SPLITTER_CDN = 'https://laquesis.data.olxcdn.com';

export const JSQR_URL = 'https://unpkg.com/jsqr@1.4.0/dist/jsQR.js';

// do not include version in the path -> always load the latest
export const WEB_VITALS_URL = 'https://unpkg.com/web-vitals/dist/web-vitals.iife.js';

export const HYDRA_HOST = 'tracking.olx-st.com/h/v2/';

// Load `/next/` suffix until all regions are migrated
// TODO: Update deploy scripts for qa and survey when done
export const LAQUESIS_QA_URL = NINJA_CDN + '/laquesisqa.js';

export const LAQUESIS_SURVEY_JS_URL = NINJA_CDN + '/laquesisSurvey.js';
export const LAQUESIS_SURVEY_CSS_FILENAME = 'laquesis-survey';

export const trackingMap = {
  H: 'hydra',
  hydra: 'hydra',
  GA: 'ga',
  ga: 'ga',
  WA: 'wa',
  wa: 'wa',
  M: 'mixpanel',
  mixpanel: 'mixpanel',
  CT: 'clevertap',
  clevertap: 'clevertap',
  G: 'gtm',
  gtm: 'gtm',
  LQ: 'laquesis',
  laquesis: 'laquesis',
  GTAG: 'gtag',
  F: 'facebook',
  facebook: 'facebook',
  BRAZE: 'braze',
};

export const Trackers = {
  H: 'H', // Hydra
  LQ: 'LQ', // Laquesis
  M: 'M', // Mixpanel
  GTM: 'G', // GTM
  GA: 'GA', // GA
  GTAG: 'GTAG', // GA4 a.k.a. GTAG
  CT: 'CT', // Clevertap,
  BRAZE: 'BRAZE', // Braze

  /* Apps only */
  F: 'F', // Facebook
  AF: 'A', // Appsflyer
  AD: 'AD', // Adjust
  NR: 'NR', // NewRelic
};

export const EVENTS = {
  LAQUESIS_READY: 'laquesis/ready',
  LAQUESIS_FLAG_ASSIGNMENT: 'laquesis/flagAssignment',
  LAQUESIS_EXP_ASSIGNMENT: 'laquesis/experimentAssignment',
  LAQUESIS_EXP_IMPRESSION: 'laquesis/experimentImpression',
};

export const ChannelName = {
  Mobile: 'mobile-html5',
  Desktop: 'desktop',
};

/* eslint-disable */
export const LATIN_MAP = {
  '\u00e1': 'a', // á
  '\u00c1': 'A', // Á
  '\u00e9': 'e', // é
  '\u00c9': 'E', // É
  '\u00ed': 'i', // í
  '\u00cd': 'I', // Í
  '\u00f3': 'o', // ó
  '\u00d3': 'O', // Ó
  '\u00fa': 'u', // ú
  '\u00da': 'U', // Ú
  '\u00f1': 'n', // ñ
  '\u00d1': 'N', // Ñ
};
/* eslint-enable */
